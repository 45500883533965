import { createContext, ReactNode, useContext } from 'react';
import { Observable } from 'rxjs';
import { useObservableWithBehaviorSubject } from '../../../hooks/UseObservable';
import { NectarineButtonColors } from '../NectarineButton/NectarineButton';

export interface ConfirmationOverlayPrompt {
  title?: string;
  headerIconClass?: string;
  text: string;
  text2?: string;
  yesButtonText?: string;
  yesButtonColor?: NectarineButtonColors;
  noButtonText?: string;
  yesAction: () => void;
  noAction?: () => void;
  doNotShowNoButton?: boolean;
}

interface ConfirmationOverlayContextType {
  confirmationOverlayPrompt$: Observable<ConfirmationOverlayPrompt | null>;
  showConfirmationOverlay: (newPrompt: ConfirmationOverlayPrompt) => void;
  hideConfirmationOverlay: () => void;
}

const ConfirmationOverlayContext = createContext<ConfirmationOverlayContextType | undefined>(undefined);

const ConfirmationOverlayContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // NOTE: We use an observable and a setter function here to expose functionality to calling code,
  // allowing things to cleanly interact with this component without triggering a rerender every time a
  // state variable changes.
  const [confirmationOverlayPrompt$, setConfirmationOverlayPrompt, getConfirmationOverlayPrompt] =
    useObservableWithBehaviorSubject<ConfirmationOverlayPrompt | null>(null);

  const showConfirmationOverlay = (newPrompt: ConfirmationOverlayPrompt) => {
    if (getConfirmationOverlayPrompt()) {
      console.log('A confirmation overlay is already displayed.');
      return;
    }

    setConfirmationOverlayPrompt(newPrompt);
  };

  const hideConfirmationOverlay = () => {
    // Give the confirmation overlay time to hide before we null things out
    setTimeout(() => {
      setConfirmationOverlayPrompt(null);
    }, 1_000);
  };

  // NOTE: We expose an observable and two methods to manipulate the underlying value of the observable.
  // Because we don't use any React state inside this code, when the underlying observable value changes, it will
  // NOT trigger a re-render of this component since all of our variable references are stable (it will
  // simply emit a value on the observable instead).
  return (
    <ConfirmationOverlayContext.Provider
      value={{
        confirmationOverlayPrompt$,
        showConfirmationOverlay,
        hideConfirmationOverlay
      }}
    >
      {children}
    </ConfirmationOverlayContext.Provider>
  );
};

const useConfirmationOverlayContext = () => {
  const context = useContext(ConfirmationOverlayContext);
  if (!context) {
    throw new Error('useConfirmationOverlayContext() must be used within an ConfirmationOverlayContext. Please verify your DOM structure.');
  }
  return context;
};

export { ConfirmationOverlayContextProvider, useConfirmationOverlayContext };
