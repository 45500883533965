import { createContext, ReactNode, useContext } from 'react';
import { Observable } from 'rxjs';
import { useObservableWithSubject } from '../../../hooks/UseObservable';

export type FormModalDataValueObject = { [index: string]: string | number | boolean | Date | undefined | null };

export interface FormModalData {
  inputs: FormModalInput[];
  modalTitle?: string;
  additionalText1?: string;
  additionalText2?: string;
  onSaveFn: (formData: FormModalDataValueObject) => void;
  shouldShowLoadingDotsOnSave?: boolean;
}

export interface FormModalInput {
  id: string;
  label: string;
  secondaryLabel?: string;
  infoIconTooltipText?: string;
  type: 'text' | 'textarea' | 'number' | 'boolean' | 'date' | 'select';
  initialValue?: string | number | boolean | Date;
  isRequired?: boolean;
  columnSpan?: 1 | 2;
  dateInputSettings?: {
    min?: Date;
    max?: Date;
    placeholderText?: string;
  };
  textInputSettings?: {
    minLength?: number;
    maxLength?: number;
    placeholderText?: string;
  };
  numericInputSettings?: {
    min?: number;
    max?: number;
    placeholderText?: string;
    roundToNearestInterval?: number;
  };
  selectInputSettings?: {
    options: NectarineInputListItem[];
  };
}

interface FormModalContextContextType {
  formModalData$: Observable<FormModalData | null>;
  turnOffSaveButtonLoadState$: Observable<void>;
  showFormModal: (newPrompt: FormModalData) => void;
  hideFormModal: () => void;
  turnOffSaveButtonLoadState: () => void;
}

const FormModalContextContext = createContext<FormModalContextContextType | undefined>(undefined);

const FormModalContextContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // NOTE: We use an observable and a setter function here to expose functionality to calling code,
  // allowing things to cleanly interact with this component without triggering a rerender every time a
  // state variable changes.
  const [formModalData$, setFormModalData, getFormModalData] = useObservableWithSubject<FormModalData | null>();
  const [turnOffSaveButtonLoadState$, setTurnOffSaveButtonLoadState] = useObservableWithSubject<void>();

  const showFormModal = (newData: FormModalData) => {
    if (getFormModalData()) {
      console.log('A form modal is already displayed.');
      return;
    }

    setFormModalData(newData);
  };

  const hideFormModal = () => {
    setFormModalData(null);
  };

  const turnOffSaveButtonLoadState = () => {
    setTurnOffSaveButtonLoadState();
  };

  // NOTE: We expose an observable and two methods to manipulate the underlying value of the observable.
  // Because we don't use any React state inside this code, when the underlying observable value changes, it will
  // NOT trigger a re-render of this component since all of our variable references are stable (it will
  // simply emit a value on the observable instead).
  return (
    <FormModalContextContext.Provider
      value={{
        formModalData$,
        turnOffSaveButtonLoadState$,
        showFormModal,
        hideFormModal,
        turnOffSaveButtonLoadState
      }}
    >
      {children}
    </FormModalContextContext.Provider>
  );
};

const useFormModalContextContext = () => {
  const context = useContext(FormModalContextContext);
  if (!context) {
    throw new Error('useFormModalContextContext() must be used within an FormModalContextContext. Please verify your DOM structure.');
  }
  return context;
};

export { FormModalContextContextProvider, useFormModalContextContext };
