import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { NavMenu } from '../NavMenu/NavMenu';
import './../PageLayout/PageLayout.scss';

interface PageLayoutProps {
  children?: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }: PageLayoutProps) => {
  return (
    <div>
      <NavMenu />

      <div className="outletWrapper">
        <Outlet />
      </div>

      {children}

      <Footer />
    </div>
  );
};

export default PageLayout;
