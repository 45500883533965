import React, { useEffect, useRef, useState } from 'react';
import { ApplicationRoutes } from '../../constants';
import AdminApiService from '../../services/api/AdminApiService';
import { useConfirmationOverlayContext } from '../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import AuthorizeService from './../api-authorization/AuthorizeService';
import './ImpersonationLabel.scss';

const ImpersonationLabel: React.FC = () => {
  const [loggedInUserFromServer, setLoggedInUserFromServer] = useState<User_BasicDetails_ViewModel | null>(null);
  const [loggedInUserFromClient, setLoggedInUserFromClient] = useState<{ email: string } | null>(null);

  // We use a ref variable for this so we don't need to include it in the useEffect dependency
  const lastLoggedInUserFromServer = useRef<User_BasicDetails_ViewModel | null>(null);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      const userViewModel = await AuthorizeService.populateActiveUser();
      setLoggedInUserFromServer(userViewModel);

      lastLoggedInUserFromServer.current = userViewModel;

      const userObj = await AuthorizeService.getUser();
      setLoggedInUserFromClient(userObj);
    };

    loadData();
  }, []);

  useEffect(() => {
    // Every so often, check if we're still impersonating a user, and if the impersonation period
    // expired, let the current user know.
    const setIntervalReference = setInterval(async () => {
      // If the user is not logged in, don't do anything
      if (!lastLoggedInUserFromServer.current) {
        return;
      }

      try {
        const userViewModel = await AuthorizeService.populateActiveUser();

        if (userViewModel && userViewModel.email !== lastLoggedInUserFromServer.current?.email) {
          showConfirmationOverlay({
            title: 'Impersonation Is No Longer Active',
            text: `You are no longer impersonating user ${lastLoggedInUserFromServer.current?.fullName}.`,
            doNotShowNoButton: true,
            headerIconClass: 'fa-duotone fa-light fa-triangle-exclamation c-orange',
            yesAction: () => {
              // There isn't anything we need to do here
            },
            yesButtonText: 'Ok'
          });

          lastLoggedInUserFromServer.current = userViewModel;
          setLoggedInUserFromServer(userViewModel);
        }
      } catch (error) {
        // Nothing we can do here
      }
    }, 30_000);

    return () => {
      clearInterval(setIntervalReference);
    };
  }, [showConfirmationOverlay]);

  return loggedInUserFromServer && loggedInUserFromClient && loggedInUserFromServer.email !== loggedInUserFromClient.email ? (
    <div className="impersonationLabel d-flex gap-3">
      <div>Impersonating {loggedInUserFromServer!.fullName}</div>

      <span
        className="closeIcon fa-regular fa-times"
        title="Stop impersonation"
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          await AdminApiService.stopImpersonatingUser();

          // Refresh the page
          const adminRootUrl = `/${ApplicationRoutes.AdminRoutes.Root}`;
          window.location.href = adminRootUrl;
        }}
      ></span>
    </div>
  ) : (
    <></>
  );
};

export default ImpersonationLabel;
