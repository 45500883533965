import { createContext, ReactNode, useContext } from 'react';
import { Observable } from 'rxjs';
import { useObservableWithSubject } from '../../../../hooks/UseObservable';

interface NotificationsPanelContextType {
  dismissCurrentMessage$: Observable<void>;
  nextMessageToShow$: Observable<NotificationsPanelMessage>;
  showSuccessNotification: (message: string, title?: string) => void;
  showSuccessTagNotification: (message: string | JSX.Element) => void;
  showErrorNotification: (message: string, title?: string) => void;
  showInfoNotification: (message: string, title?: string) => void;
  dismissCurrentMessage: () => void;
}

export interface NotificationsPanelMessage {
  message: string | JSX.Element;
  title?: string;
  type?: 'success' | 'error' | 'info' | 'success-tag';
}

const NotificationsPanelContext = createContext<NotificationsPanelContextType | undefined>(undefined);

const NotificationsPanelContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // NOTE: We use an observable and a setter function here to expose functionality to calling code,
  // allowing things to cleanly interact with this component without triggering a rerender every time a
  // state variable changes.
  const [nextMessageToShow$, setNextMessageToShow] = useObservableWithSubject<NotificationsPanelMessage>();
  const [dismissCurrentMessage$, dismissCurrentMessage] = useObservableWithSubject<void>();

  const showSuccessNotification = (message: string, title?: string) => {
    setNextMessageToShow({ message, title, type: 'success' });
  };

  const showSuccessTagNotification = (message: string | JSX.Element) => {
    setNextMessageToShow({ message, title: '', type: 'success-tag' });
  };

  const showErrorNotification = (message: string, title?: string) => {
    setNextMessageToShow({ message, title, type: 'error' });
  };

  const showInfoNotification = (message: string, title?: string) => {
    setNextMessageToShow({ message, title, type: 'info' });
  };

  // NOTE: We expose an observable and two methods to manipulate the underlying value of the observable.
  // Because we don't use any React state inside this code, when the underlying observable value changes, it will
  // NOT trigger a re-render of this component since all of our variable references are stable (it will
  // simply emit a value on the observable instead).
  return (
    <NotificationsPanelContext.Provider
      value={{
        dismissCurrentMessage$,
        nextMessageToShow$,
        dismissCurrentMessage,
        showSuccessNotification,
        showSuccessTagNotification,
        showErrorNotification,
        showInfoNotification
      }}
    >
      {children}
    </NotificationsPanelContext.Provider>
  );
};

const useNotificationsPanelContext = () => {
  const context = useContext(NotificationsPanelContext);
  if (!context) {
    throw new Error('useNotificationsPanelContext() must be used within an NotificationsPanelContext. Please verify your DOM structure.');
  }

  return context;
};

export { NotificationsPanelContextProvider, useNotificationsPanelContext };
