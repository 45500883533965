import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { AppSettings } from '../../constants';
import LoadingSpinner from '../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

interface RedirectProps {
  url: string;
}

const Redirect = ({ url }: RedirectProps) => {
  window.location.href = url;

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Redirecting...</title>
      </Helmet>
      <LoadingSpinner />
    </Container>
  );
};

export default Redirect;
