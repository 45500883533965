import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdvisorFilterSortOptions } from '../components/Pages/Advisors/AdvisorResults/AdvisorsResults';
import { ApplicationRoutes } from '../constants';
import useUrlHelper from './UseUrlHelper';

interface UseNavigationService {
  navigateTo_Admin_EventsView: () => void;
  navigateTo_Admin_ThePlanDetailsView: () => void;
  navigateTo_Admin_ThePlanEdit: (thePlanInstanceId?: string) => void;
  navigateTo_Admin_ThePlanSignupEdit: (thePlanSignupId?: string) => void;
  navigateTo_Admin_ToolsView: () => void;
  navigateTo_Admin_UsersView: () => void;
  navigateTo_AdminDashboard: () => void;
  navigateTo_AdvisorsDetails: (advisorId: string) => void;
  navigateTo_AdvisorsResults: (sort?: AdvisorFilterSortOptions, filters?: string[]) => void;
  navigateTo_Client_Reviews: () => void;
  navigateTo_Home: () => void;
  navigateTo_Error: () => void;
  navigateTo_ThePlanTerms: (advisorId: string) => void;
}

const useNavigationService = (): UseNavigationService => {
  const navigate = useNavigate();

  const { getQueryStringParameter } = useUrlHelper();

  const navigateTo_AdvisorsResults = (sort?: AdvisorFilterSortOptions | null, filters?: string[]): void => {
    // Update the URL to reflect the selected attributes, like ?filters=real-estate+retirement
    sort = sort ?? getQueryStringParameter<AdvisorFilterSortOptions>('sort') ?? 'available';

    // If it's the default sort option, we don't show it in the URL
    if (sort === 'available') {
      sort = null;
    }

    let newUrl = '';

    if (filters?.length === 1) {
      newUrl += `/${filters[0]}`;

      if (sort) {
        newUrl += `?sort=${sort}`;
      }
    } else if ((filters?.length ?? 0) > 0) {
      newUrl += `?filters=${filters!.join('+')}`;
      if (sort) {
        newUrl += `&sort=${sort}`;
      }
    }

    navigate(`/${ApplicationRoutes.Advisors}${newUrl}`);
  };

  const navigateTo_AdvisorsDetails = useCallback(
    (advisorSlug: string): void => {
      navigate(`/${ApplicationRoutes.Advisor}/${advisorSlug}`);
    },
    [navigate]
  );

  const navigateTo_Client_Reviews = useCallback((): void => {
    navigate(`/${ApplicationRoutes.ClientAccountRoutes.Reviews_Full}`);
  }, [navigate]);

  const navigateTo_Home = useCallback((): void => {
    navigate(ApplicationRoutes.Root);
  }, [navigate]);

  const navigateTo_Error = useCallback((): void => {
    navigate(`/${ApplicationRoutes.Error}`);
  }, [navigate]);

  const navigateTo_ThePlanTerms = useCallback(
    (advisorSlug: string): void => {
      navigate(`/${ApplicationRoutes.ThePlan_Terms}/${advisorSlug}`);
    },
    [navigate]
  );

  const navigateTo_Admin_ThePlanEdit = useCallback(
    (thePlanInstanceId?: string): void => {
      // NOTE: We generate a reload ID timestamp so the page component knows when to reload from scratch
      const reloadId = new Date().getTime();
      navigate(`/${ApplicationRoutes.AdminRoutes.ThePlan_Edit_Full}/${reloadId}/${thePlanInstanceId ?? ''}`);
    },
    [navigate]
  );

  const navigateTo_Admin_ThePlanSignupEdit = useCallback(
    (thePlanSignupId?: string): void => {
      // NOTE: We generate a reload ID timestamp so the page component knows when to reload from scratch
      const reloadId = new Date().getTime();
      navigate(`/${ApplicationRoutes.AdminRoutes.ThePlan_Signup_Edit_Full}/${reloadId}/${thePlanSignupId ?? ''}`);
    },
    [navigate]
  );

  const navigateTo_Admin_ThePlanDetailsView = useCallback((): void => {
    navigate(`/${ApplicationRoutes.AdminRoutes.ThePlan_Full}`);
  }, [navigate]);

  const navigateTo_Admin_EventsView = useCallback((): void => {
    navigate(`/${ApplicationRoutes.AdminRoutes.Events_Full}`);
  }, [navigate]);

  const navigateTo_Admin_ToolsView = useCallback((): void => {
    navigate(`/${ApplicationRoutes.AdminRoutes.Tools_Full}`);
  }, [navigate]);

  const navigateTo_Admin_UsersView = useCallback((): void => {
    navigate(`/${ApplicationRoutes.AdminRoutes.Users_Full}`);
  }, [navigate]);

  const navigateTo_AdminDashboard = useCallback((): void => {
    navigate(`/${ApplicationRoutes.AdminRoutes.Root}`);
  }, [navigate]);

  return {
    navigateTo_AdminDashboard,
    navigateTo_Admin_EventsView,
    navigateTo_Admin_ThePlanDetailsView,
    navigateTo_Admin_ThePlanEdit,
    navigateTo_Admin_ThePlanSignupEdit,
    navigateTo_Admin_ToolsView,
    navigateTo_Admin_UsersView,
    navigateTo_AdvisorsDetails,
    navigateTo_AdvisorsResults,
    navigateTo_Client_Reviews,
    navigateTo_Home,
    navigateTo_Error,
    navigateTo_ThePlanTerms
  };
};

export default useNavigationService;
