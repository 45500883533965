import AppRoutes from './AppRoutes';
import ImpersonationLabel from './components/ImpersonationLabel/ImpersonationLabel';
import './styles/custom.scss';

const App: React.FC = () => {
  return (
    <>
      <ImpersonationLabel />

      <AppRoutes />
    </>
  );
};

export default App;
