const accountRoot = 'account';
const adminRoot = 'admin';
const authenticationRoot = 'authentication';
const dashboardRoot = 'dashboard';
const servicesRoot = 'services';
const shareRoot = 'r';

const AppSettings = {
  ApplicationName: 'Nectarine',
  PrivacyPolicyUrl: 'https://s3.amazonaws.com/files.hellonectarine.com/SC8aOavKzk6B2I4Mzd8yvw-nectarine-privacy-policy.pdf',
  NectarineADV2A: 'https://s3.amazonaws.com/files.hellonectarine.com/7jXr8nRu0Ot7MGI28ldg-nectarine-adv-part-2a.pdf',
  NectarineCRS: 'https://s3.amazonaws.com/files.hellonectarine.com/MsArmVi5IkGYOOVyN8jYzQ-nectarine-form-crs.pdf',
  ThePlanWelcomePDF: 'https://s3.us-east-1.amazonaws.com/files.hellonectarine.com/Nectarine+Financial+Plan+Welcome.pdf',
  WebsiteRoot: 'https://hellonectarine.com'
};

const AttributeCategories = {
  ICanHelpYouWith: 1,
  IEnjoyWorkingWith: 2
};

const AttributeCategoriesText = {
  ICanHelpYouWith: 'I Can Help You With',
  IEnjoyWorkingWith: 'I Enjoy Working With'
};

// Features that exist in code but are not visible to all users yet
const FeatureFlags = {
  WinterEasterEggs: 'WinterEasterEggs',
  ThePlanWelcomeEmail: 'ThePlanWelcomeEmail'
};

const RecordingStatus = {
  Processing: 'Processing',
  SavedToS3: 'SavedToS3',
  SavedToS3AndDeletedFromZoom: 'SavedToS3AndDeletedFromZoom',
  Error: 'Error'
};

const LocalStorageKeys = {
  Advisor: {
    MeetingNotes: 'meetingNotes'
  },
  General: {
    ReturnUrl: 'returnUrl',
    LastUrls: 'lastUrls'
  }
};

const UserLoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register'
};

const UserLogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

const ValueLimits = {
  Url: 2050,
  Email_MaxLength: 250,
  Name_MinLength: 2,
  Name_MaxLength: 50,
  Notes_MaxLength: 2_000,
  Password_MinLength: 8,
  Password_MaxLength: 50,
  TheHour_Price_MinValue: 100,
  TheHour_Price_MaxValue: 400,
  ThePlan_ExtraInformation_MaxLength: 2_000,
  ThePlan_DeductionPrice_MinValue: 0,
  ThePlan_DeductionPrice_MaxValue: 500,
  ThePlan_Price_MinValue: 500,
  ThePlan_Price_MaxValue: 5_000,
  User_Slug_MinLength: 3,
  User_Slug_MaxLength: 50
};

const Products = {
  TheHour: 'TheHour',
  ThePlan: 'ThePlan'
};

const ProductNames = {
  TheHour: 'The Hour',
  ThePlan: 'The Plan'
};

const ThePlanSignupStatus = {
  SendInitialFollowUp: 'SendInitialFollowUp',
  FollowUpAtLaterDate: 'FollowUpAtLaterDate',
  ContactAdvisor: 'ContactAdvisor',
  WaitUntilStartDate: 'WaitUntilStartDate',
  SendWelcomeEmail: 'SendWelcomeEmail',
  WaitingForAdvisor: 'WaitingForAdvisor',
  WaitingForClient: 'WaitingForClient',
  WelcomeEmailSent: 'WelcomeEmailSent',
  Cancelled: 'Cancelled',
  ContactClient: 'ContactClient'
};

const StripeInvoiceStatuses = {
  Draft: 'draft',
  Open: 'open',
  Paid: 'paid',
  Uncollectible: 'uncollectible',
  Void: 'void'
};

const ApplicationRoutes = {
  About: 'about',
  Advisor: 'advisor',
  Advisors: 'advisors',
  Affiliates: 'affiliates',
  Apply: 'apply',
  Booked: 'booked',
  Contact: 'contact',
  Error: 'error',
  FlatFeeFinancialPlanners: 'flat-fee-financial-planners',
  HourlyFinancialAdvisors: 'hourly-financial-advisors',
  IntakeForm: 'https://hellonectarine.typeform.com/to/QdrDHmPg',
  LeaveAReview: 'leaveareview',
  NonUS: 'non-us',
  Privacy: 'privacy',
  Recruit: 'recruit',
  Register: 'register',
  Register_Affiliate: 'register?affiliate=true',
  Root: '/',
  Services: 'services',
  Terms: 'terms',
  ThePlan: 'the-plan',
  ThePlan_Signup: 'https://hellonectarine.typeform.com/theplansignup',
  ThePlan_Terms: 'theplan-terms',
  AdminRoutes: {
    Root: adminRoot,
    AdvisorAttributes: 'advisorattributes',
    AdvisorAttributes_Full: `${adminRoot}/advisorattributes`,
    AdvisorPayments: 'advisorpayments',
    AdvisorPayments_Full: `${adminRoot}/advisorpayments`,
    AffiliatePayments: 'affiliatepayments',
    AffiliatePayments_Full: `${adminRoot}/affiliatepayments`,
    Documents: 'documents',
    Documents_Full: `${adminRoot}/documents`,
    Events: 'events',
    Events_Full: `${adminRoot}/events`,
    IntakeForms: 'intakeforms',
    IntakeForms_Full: `${adminRoot}/intakeforms`,
    Recordings: 'recordings',
    Recordings_Full: `${adminRoot}/recordings`,
    Reports: 'reports',
    Reports_Full: `${adminRoot}/reports`,
    FeatureFlags: 'feature-flags',
    FeatureFlags_Full: `${adminRoot}/feature-flags`,
    ThePlan: 'the-plan',
    ThePlan_Full: `${adminRoot}/the-plan`,
    ThePlan_Edit: 'the-plan/edit',
    ThePlan_Edit_Full: `${adminRoot}/the-plan/edit`,
    ThePlan_Signup_Edit: 'the-plan-signup/edit',
    ThePlan_Signup_Edit_Full: `${adminRoot}/the-plan-signup/edit`,
    Reviews: 'reviews',
    Reviews_Full: `${adminRoot}/reviews`,
    RolesRoutes: {
      Root: 'roles',
      Root_Full: `${adminRoot}/roles`,
      Create: 'create',
      Create_Full: `${adminRoot}/roles/create`
    },
    Search_Full: `${adminRoot}/search`,
    Search: 'search',
    Tools_Full: `${adminRoot}/tools`,
    Tools: 'tools',
    UserAgreements: 'useragreements',
    UserAgreements_Full: `${adminRoot}/useragreements`,
    Users_Full: `${adminRoot}/users`,
    Users: 'users'
  },
  AdvisorAccountRoutes: {
    Root: dashboardRoot,
    Client: 'client',
    Client_Full: `${dashboardRoot}/client`,
    Compliance: `compliance`,
    Compliance_Full: `${dashboardRoot}/compliance`,
    Payments: 'payments',
    Payments_Full: `${dashboardRoot}/payments`,
    Profile: 'profile',
    Profile_Full: `${dashboardRoot}/profile`,
    Reviews: 'reviews',
    Reviews_Full: `${dashboardRoot}/reviews`
  },
  AuthenticationRoutes: {
    Root: authenticationRoot,
    ApiAuthorizationClientConfigurationUrl: `_configuration/${AppSettings.ApplicationName}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    Login: `${authenticationRoot}/login`,
    LoginDefaultRedirectPath: '/',
    LoginFailed: `${authenticationRoot}/${UserLoginActions.LoginFailed}`,
    LoginCallback: `${authenticationRoot}/${UserLoginActions.LoginCallback}`,
    Logout: `${authenticationRoot}/logout`,
    LoggedOut: `${authenticationRoot}/${UserLogoutActions.LoggedOut}`,
    LogOutCallback: `${authenticationRoot}/${UserLogoutActions.LogoutCallback}`,
    Profile: `${authenticationRoot}/${UserLoginActions.Profile}`
  },
  ClientAccountRoutes: {
    Root: accountRoot,
    Advisors: 'advisors',
    Manage_Full: 'Identity/Account/Manage',
    Reviews: 'reviews',
    Reviews_Full: `${accountRoot}/reviews`,
    Share: 'share',
    Share_Full: `${accountRoot}/share`
  },
  SeoLandingPagesRoutes: {
    Root: servicesRoot,
    FeeOnlyFiduciaries: 'fee-only-fiduciaries',
    FeeOnlyFiduciaries_Full: `${servicesRoot}/fee-only-fiduciaries`,
    FiduciaryCFPS: 'fiduciary-cfps',
    FiduciaryCFPS_Full: `${servicesRoot}/fiduciary-cfps`,
    FlatRateFinancialPlanners: 'flat-rate-financial-planners',
    FlatRateFinancialPlanners_Full: `${servicesRoot}/flat-rate-financial-planners`,
    IndependentFinancialAdvisors: 'independent-financial-advisors',
    IndependentFinancialAdvisors_Full: `${servicesRoot}/independent-financial-advisors`
  },
  SharingPageRoutes: {
    Root: shareRoot
  }
};

const UserRoles = {
  Admin: 'Admin',
  Advisor: 'Advisor',
  Affiliate: 'Affiliate',
  Consumer: 'Consumer'
};

const CommonApiBadRequestErrors = {
  NotLoggedIn: 'notLoggedIn'
};

const CommonErrorMessages = {
  General: `We're sorry, but an error occurred.`,
  NotLoggedIn: 'You must be logged in to continue.'
};

const HTTPStatuses = {
  Ok: 200,
  NoContent: 204,
  BadRequest: 400,
  NotFound: 404
};

const CommonQueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

const AdminEventStatuses = {
  Active: 'Active'
};

const ThePlanMeetingStatus = {
  Scheduled: 'Scheduled',
  Cancelled: 'Cancelled',
  Completed: 'Completed'
};

enum SortOptions {
  Newest = 1,
  Oldest = 2,
  Highest = 3,
  Lowest = 4
}

export {
  AdminEventStatuses,
  ApplicationRoutes,
  AppSettings,
  AttributeCategories,
  AttributeCategoriesText,
  CommonApiBadRequestErrors,
  CommonErrorMessages,
  CommonQueryParameterNames,
  FeatureFlags,
  HTTPStatuses,
  LocalStorageKeys,
  ProductNames,
  Products,
  RecordingStatus,
  SortOptions,
  StripeInvoiceStatuses,
  ThePlanMeetingStatus,
  ThePlanSignupStatus,
  UserLoginActions,
  UserLogoutActions,
  UserRoles,
  ValueLimits
};
