import cloneDeep from 'lodash/cloneDeep';
import React, { lazy, ReactNode, Suspense, useEffect } from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { AppRoutesConfiguration, getMissingAppRouteComponents, setAppRouteComponent } from './AppRouteDefinitions';

import LoadingBlank from './components/LoadingBlank/LoadingBlank';
import Home from './components/Pages/Home/Home';
import PageLayout from './components/Pages/Layout/PageLayout/PageLayout';
import Redirect from './components/Redirect/Redirect';

const About = lazy(() => import('./components/Pages/About/About'));
const AdvisorLayout = lazy(() => import('./components/Advisor/AdvisorLayout'));
const Login = lazy(() => import('./components/api-authorization/Login'));
const Logout = lazy(() => import('./components/api-authorization/Logout'));
const AdvisorAccountClient = lazy(() => import('./components/Pages/Account/Advisor/AdvisorAccountClient/AdvisorAccountClient'));
const AdvisorAccountComplianceReport = lazy(
  () => import('./components/Pages/Account/Advisor/AdvisorAccountComplianceReport/AdvisorAccountComplianceReport')
);
const AdvisorAccountPayments = lazy(() => import('./components/Pages/Account/Advisor/AdvisorAccountPayments/AdvisorAccountPayments'));
const AdvisorAccountProfile = lazy(() => import('./components/Pages/Account/Advisor/AdvisorAccountProfile/AdvisorAccountProfile'));
const AdvisorAccountReviews = lazy(() => import('./components/Pages/Account/Advisor/AdvisorAccountReviews/AdvisorAccountReviews'));
const AdvisorIndex = lazy(() => import('./components/Pages/Account/Advisor/Root/AdvisorIndex'));
const ClientAccountAffiliate = lazy(() => import('./components/Pages/Account/Client/ClientAccountAffiliate/ClientAccountAffiliate'));
const ClientAccountReviews = lazy(() => import('./components/Pages/Account/Client/ClientAccountReviews/ClientAccountReviews'));
const AccountLayout = lazy(() => import('./components/Pages/Account/Client/Layout/Layout'));
const AccountIndex = lazy(() => import('./components/Pages/Account/Client/Root/AccountIndex'));
const AccountRegister = lazy(() => import('./components/Pages/Account/Register/AccountRegister'));
const AdvisorAttributes = lazy(() => import('./components/Pages/Admin/AdminAdvisorAttributes/AdminAdvisorAttributes'));
const AdminComplianceReport = lazy(() => import('./components/Pages/Admin/AdminComplianceReport/AdminComplianceReport'));
const AdminCreateRole = lazy(() => import('./components/Pages/Admin/AdminCreateRole/AdminCreateRole'));
const AdminDocuments = lazy(() => import('./components/Pages/Admin/AdminDocuments/AdminDocuments'));
const AdminEditEvent = lazy(() => import('./components/Pages/Admin/AdminEditEvent/AdminEditEvent'));
const AdminEditRecording = lazy(() => import('./components/Pages/Admin/AdminEditRecording/AdminEditRecording'));
const AdminEditRole = lazy(() => import('./components/Pages/Admin/AdminEditRole/AdminEditRole'));
const AdminEditThePlan = lazy(() => import('./components/Pages/Admin/AdminEditThePlan/AdminEditThePlan'));
const AdminEditThePlanSignup = lazy(() => import('./components/Pages/Admin/AdminEditThePlanSignup/AdminEditThePlanSignup'));
const AdminEditUser = lazy(() => import('./components/Pages/Admin/AdminEditUser/AdminEditUser'));
const AdminEvents = lazy(() => import('./components/Pages/Admin/AdminEvents/AdminEvents'));
const AdminFeatureFlags = lazy(() => import('./components/Pages/Admin/AdminFeatureFlags/AdminFeatureFlags'));
const AdminIntakeForms = lazy(() => import('./components/Pages/Admin/AdminIntakeForms/AdminIntakeForms'));
const AdminAdvisorPayments = lazy(() => import('./components/Pages/Admin/AdminPayments_Advisor/AdminPayments_Advisor'));
const AdminAffiliatePayments = lazy(() => import('./components/Pages/Admin/AdminPayments_Affiliate/AdminPayments_Affiliate'));
const AdminRecordings = lazy(() => import('./components/Pages/Admin/AdminRecordings/AdminRecordings'));
const AdminReports = lazy(() => import('./components/Pages/Admin/AdminReports/AdminReports'));
const AdminReviews = lazy(() => import('./components/Pages/Admin/AdminReviews/AdminReviews'));
const AdminRoles = lazy(() => import('./components/Pages/Admin/AdminRoles/AdminRoles'));
const AdminSearch = lazy(() => import('./components/Pages/Admin/AdminSearch/AdminSearch'));
const AdminThePlan = lazy(() => import('./components/Pages/Admin/AdminThePlan/AdminThePlan'));
const AdminTools = lazy(() => import('./components/Pages/Admin/AdminTools/AdminTools'));
const AdminUserAgreements = lazy(() => import('./components/Pages/Admin/AdminUserAgreements/AdminUserAgreements'));
const AdminUsers = lazy(() => import('./components/Pages/Admin/AdminUsers/AdminUsers'));
const AdminLayout = lazy(() => import('./components/Pages/Admin/Layout/AdminLayout'));
const AdminIndex = lazy(() => import('./components/Pages/Admin/Root/Index'));
const AdvisorDetails = lazy(() => import('./components/Pages/Advisors/AdvisorDetails/AdvisorDetails'));
const AdvisorsResults = lazy(() => import('./components/Pages/Advisors/AdvisorResults/AdvisorsResults'));
const Affiliates = lazy(() => import('./components/Pages/Affiliates/Affiliates'));
const Booked = lazy(() => import('./components/Pages/Booked/Booked'));
const Contact = lazy(() => import('./components/Pages/Contact/Contact'));
const ErrorPage = lazy(() => import('./components/Pages/Errors/Error/ErrorPage'));
const NotFound = lazy(() => import('./components/Pages/Errors/NotFound/NotFound'));
const AffiliateLandingPage = lazy(() => import('./components/Pages/LandingPages/AffiliateLandingPage'));
const LandingPage_FlatFeeFinancialPlanners = lazy(() => import('./components/Pages/LandingPages/FlatFeeFinancialPlanners'));
const LandingPage_HourlyFinancialAdvisors = lazy(() => import('./components/Pages/LandingPages/HourlyFinancialAdvisors'));
const LandingPages = lazy(() => import('./components/Pages/LandingPages/LandingPages'));
const LeaveAReview = lazy(() => import('./components/Pages/LeaveAReview/LeaveAReview'));
const NonUnitedStates = lazy(() => import('./components/Pages/NonUnitedStates/NonUnitedStates'));
const Privacy = lazy(() => import('./components/Pages/Privacy/Privacy'));
const Recruit = lazy(() => import('./components/Pages/Recruit/Recruit'));
const Terms = lazy(() => import('./components/Pages/Terms/Terms/Terms'));
const ThePlanTerms = lazy(() => import('./components/Pages/Terms/ThePlanTerms/ThePlanTerms'));
const ThePlan = lazy(() => import('./components/Pages/ThePlan/ThePlan'));
const RoleRestriction = lazy(() => import('./components/RoleRestriction/RoleRestriction'));
const ScrollToTop = lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const ConfirmationOverlay = lazy(() => import('./components/ui/ConfirmationOverlay/ConfirmationOverlay'));
const FormModal = lazy(() => import('./components/ui/FormModal/FormModal'));
const LoadingOverlay = lazy(() => import('./components/ui/LoadingAnimations/LoadingOverlay/LoadingOverlay'));
const NotificationPanel = lazy(() => import('./components/ui/Notifications/NotificationsPanel/NotificationsPanel'));

import { ApplicationRoutes, UserLoginActions, UserLogoutActions, UserRoles } from './constants';
import { useGeneralContext } from './services/GeneralContext';

const AppRoutes: React.FC = () => {
  const { initializeFeatureFlags } = useGeneralContext();

  useEffect(() => {
    const loadData = async () => {
      await initializeFeatureFlags();
    };
    loadData();
  }, [initializeFeatureFlags]);

  return (
    <div>
      <ScrollToTop />

      <LoadingOverlay />

      <ConfirmationOverlay />

      <NotificationPanel />

      <FormModal />

      <Suspense fallback={<LoadingBlank />}>
        <Routes>
          {routesJSX.map((currJSX, i) => {
            return <React.Fragment key={i}>{currJSX}</React.Fragment>;
          })}

          <Route path={ApplicationRoutes.Root} element={<PageLayout />}>
            <Route path="*" element={<NotFound />} /> {/* Catch-all route 404 page */}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

// NOTE: We define our routes structure in a separate Typescript file, which we then import into this file
// and marry up routes with JSX components.
// We do this because we generate a sitemap on build using the routes structure Typescript file, and defining
// JSX inside of that file leads to weirdness when run inside a node environment.
const appRoutesConfig = cloneDeep(AppRoutesConfiguration);
setAppRouteComponent(appRoutesConfig, 'Root', <PageLayout />);
setAppRouteComponent(appRoutesConfig, 'Home', <Home />);
setAppRouteComponent(appRoutesConfig, 'About', <About />);
setAppRouteComponent(appRoutesConfig, 'AdvisorDetails', <AdvisorDetails />);
setAppRouteComponent(appRoutesConfig, 'Advisors_SingleFilter', <AdvisorsResults />);
setAppRouteComponent(appRoutesConfig, 'Advisors_StateAndSingleFilter', <AdvisorsResults />);
setAppRouteComponent(appRoutesConfig, 'Advisors', <AdvisorsResults />);
setAppRouteComponent(appRoutesConfig, 'Affiliates', <Affiliates />);
setAppRouteComponent(appRoutesConfig, 'AffiliatesLandingPage', <AffiliateLandingPage />);
setAppRouteComponent(appRoutesConfig, 'Apply', <Redirect url="https://hellonectarine.typeform.com/advisors" />);
setAppRouteComponent(appRoutesConfig, 'Booked', <Booked />);
setAppRouteComponent(appRoutesConfig, 'Contact', <Contact />);
setAppRouteComponent(appRoutesConfig, 'Error', <ErrorPage />);
setAppRouteComponent(appRoutesConfig, 'FlatFeeFinancialPlanners', <LandingPage_FlatFeeFinancialPlanners />);
setAppRouteComponent(appRoutesConfig, 'HourlyFinancialAdvisors', <LandingPage_HourlyFinancialAdvisors />);
setAppRouteComponent(appRoutesConfig, 'LeaveAReview', <LeaveAReview />);
setAppRouteComponent(appRoutesConfig, 'NonUS', <NonUnitedStates />);
setAppRouteComponent(appRoutesConfig, 'Privacy', <Privacy />);
setAppRouteComponent(appRoutesConfig, 'Register', <AccountRegister />);
setAppRouteComponent(appRoutesConfig, 'Recruit', <Recruit />);
setAppRouteComponent(appRoutesConfig, 'Services', <LandingPages />);
setAppRouteComponent(appRoutesConfig, 'Terms', <Terms />);
setAppRouteComponent(appRoutesConfig, 'ThePlan_Terms', <ThePlanTerms />);
setAppRouteComponent(appRoutesConfig, 'ThePlan', <ThePlan />);

// Admin Routes
setAppRouteComponent(appRoutesConfig, 'AdminRoot', <RoleRestriction allowedRoles={[UserRoles.Admin]} element={<AdminLayout />} />);
setAppRouteComponent(appRoutesConfig, 'AdminRootIndex', <AdminIndex />);
setAppRouteComponent(appRoutesConfig, 'AdminComplianceReport', <AdminComplianceReport />);
setAppRouteComponent(appRoutesConfig, 'AdminSearch', <AdminSearch />);

setAppRouteComponent(appRoutesConfig, 'AdminUsersIndex', <AdminUsers />);
setAppRouteComponent(appRoutesConfig, 'AdminEditUser', <AdminEditUser />);

setAppRouteComponent(appRoutesConfig, 'AdminRolesIndex', <AdminRoles />);
setAppRouteComponent(appRoutesConfig, 'AdminCreateRole', <AdminCreateRole />);
setAppRouteComponent(appRoutesConfig, 'AdminEditRole', <AdminEditRole />);

setAppRouteComponent(appRoutesConfig, 'AdminAdvisorAttributes', <AdvisorAttributes />);
setAppRouteComponent(appRoutesConfig, 'AdminAdvisorPayments', <AdminAdvisorPayments />);
setAppRouteComponent(appRoutesConfig, 'AdminAffiliatePayments', <AdminAffiliatePayments />);
setAppRouteComponent(appRoutesConfig, 'AdminDocuments', <AdminDocuments />);
setAppRouteComponent(appRoutesConfig, 'AdminIntakeForms', <AdminIntakeForms />);
setAppRouteComponent(appRoutesConfig, 'AdminReports', <AdminReports />);
setAppRouteComponent(appRoutesConfig, 'AdminThePlan', <AdminThePlan />);
setAppRouteComponent(appRoutesConfig, 'AdminTools', <AdminTools />);
setAppRouteComponent(appRoutesConfig, 'AdminEditThePlan', <AdminEditThePlan />);
setAppRouteComponent(appRoutesConfig, 'AdminEditThePlanSignup', <AdminEditThePlanSignup />);
setAppRouteComponent(appRoutesConfig, 'AdminFeatureFlags', <AdminFeatureFlags />);
setAppRouteComponent(appRoutesConfig, 'AdminReviews', <AdminReviews />);
setAppRouteComponent(appRoutesConfig, 'AdminUserAgreements', <AdminUserAgreements />);

setAppRouteComponent(appRoutesConfig, 'AdminEventsIndex', <AdminEvents />);
setAppRouteComponent(appRoutesConfig, 'AdminEditEvent', <AdminEditEvent />);

setAppRouteComponent(appRoutesConfig, 'AdminRecordingsIndex', <AdminRecordings />);
setAppRouteComponent(appRoutesConfig, 'AdminEditRecording', <AdminEditRecording />);

// Client Routes
setAppRouteComponent(
  appRoutesConfig,
  'ClientAccountRoot',
  <RoleRestriction allowedRoles={[UserRoles.Admin, UserRoles.Consumer]} element={<AccountLayout />} />
);
setAppRouteComponent(appRoutesConfig, 'ClientAccountIndex', <AccountIndex />);
setAppRouteComponent(appRoutesConfig, 'ClientAccountAffiliate', <ClientAccountAffiliate />);
setAppRouteComponent(appRoutesConfig, 'ClientAccountReviews', <ClientAccountReviews />);

// Advisor Routes
setAppRouteComponent(
  appRoutesConfig,
  'AdvisorAccountRoot',
  <RoleRestriction allowedRoles={[UserRoles.Admin, UserRoles.Advisor]} element={<AdvisorLayout />} />
);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountIndex', <AdvisorIndex />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountClient', <AdvisorAccountClient />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountComplianceReport', <AdvisorAccountComplianceReport />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountPayments', <AdvisorAccountPayments />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountProfile', <AdvisorAccountProfile />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountReviews', <AdvisorAccountReviews />);

// Authentication Routes
setAppRouteComponent(appRoutesConfig, 'AuthenticationLogin', <Login action={UserLoginActions.Login} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLoginFailed', <Login action={UserLoginActions.LoginFailed} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLoginCallback', <Login action={UserLoginActions.LoginCallback} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationProfile', <Login action={UserLoginActions.Profile} />);

setAppRouteComponent(appRoutesConfig, 'AuthenticationLogout', <Logout action={UserLogoutActions.Logout} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLogOutCallback', <Logout action={UserLogoutActions.LogoutCallback} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLoggedOut', <Logout action={UserLogoutActions.LoggedOut} />);

const missingComponents = getMissingAppRouteComponents(appRoutesConfig);

if (missingComponents.length) {
  const errorMessage = `${missingComponents.length} route(s) were missing a component in AppRoutes.tsx`;
  console.error(errorMessage, missingComponents);
  throw new Error(errorMessage + '\n' + JSON.stringify(missingComponents));
}

const generateRoutesJSX = (routesToRender: AppRouteListing[]): ReactNode[] => {
  const returnValue: ReactNode[] = [];

  routesToRender.forEach((currRoute) => {
    const routeProps: RouteProps = {
      index: currRoute.isIndex,
      path: currRoute.path ?? undefined,
      element: currRoute.component ? (currRoute.component as ReactNode) : undefined
    };

    let childrenElements: ReactNode[] = [];
    if (currRoute.subRoutes?.length) {
      childrenElements = generateRoutesJSX(currRoute.subRoutes);
    }

    const newElement = React.createElement(
      Route,
      { ...routeProps, key: currRoute.name },
      childrenElements.length ? childrenElements : undefined
    );

    returnValue.push(newElement);
  });

  return returnValue;
};

const routesJSX = generateRoutesJSX(appRoutesConfig);

export default AppRoutes;
