import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AppSettings } from '../../constants';

export const DisclaimerRow: React.FC = () => {
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const toggleDisclaimer = () => setDisclaimerModal(!disclaimerModal);

  return (
    <Row className="disclaimer-row">
      <Col className="center">
        <div className="disclaimer-text">
          The endorsements displayed on this Website have been provided by clients who have completed an engagement with an Advisor. No
          compensation was paid for any endorsement and the endorsements may not necessarily be representative of all Nectarine experiences.
        </div>
        <div className="btn btn-link" onClick={toggleDisclaimer}>
          Disclaimer
        </div>

        <Modal isOpen={disclaimerModal} toggle={toggleDisclaimer} className="disclaimer-modal">
          <ModalHeader toggle={toggleDisclaimer}>Endorsement &amp; Advisor Disclaimer</ModalHeader>
          <ModalBody className="disclaimer-body">
            <h2>Summary</h2>

            <p>
              Nectarine Financial, Inc. ("Nectarine") doesn't pay for, filter, or hide any client reviews. Each individual advisor displayed
              on <a href={AppSettings.WebsiteRoot}>{AppSettings.WebsiteRoot}</a> (this “Website”) may be registered with Nectarine as an
              investment adviser representative (an “Nectarine Advisor”), registered with another investment adviser independent from and
              unaffiliated with Nectarine as an investment adviser representative (an “Independent Advisor”), or both (a “Dually-Registered
              Advisor”). Nectarine Advisors, Independent Advisors, and Dually-Registered Advisors are collectively referred to on this
              Website as “Advisors”. Please refer to each Advisor's profile for further detail.
            </p>
            <h3>Endorsement Disclosure</h3>
            <p>
              Nectarine makes no representation of what a prospective client's particular experience with any Advisor may be. Prospective
              clients of an Advisor are highly encouraged to review such Advisor's Form ADV Part 2B brochure supplement, the Form ADV Part
              2A for such Advisor's employing investment advisory firm, and, if applicable, Form CRS relationship summary. Prospective
              clients retain the sole and absolute discretion to retain or not to retain any Advisor. The reviews and ratings by an
              Advisor's clients are solely for prospective clients' informational purposes only, and reflect the experience and opinion of
              such clients. Nectarine and/or the Advisor may have contacted its clients to leave a review and rating, or an Advisor's
              clients may have independently elected to post reviews and ratings of their own volition. Such experiences and opinions may
              differ from client to client, and may differ from a prospective client's experience. Prospective clients are encouraged to
              independently verify all information deemed relevant when evaluating an Advisor.
            </p>
            <p>
              To maintain a listing on this Website and be an Independent Advisor to whom Nectarine may refer prospective clients, an
              Independent Advisor must pay a fee to Nectarine (the "Referral Fee"). The Referral Fee is a portion of the financial planning
              or other investment advisory fee payable to an Independent Advisor and collected by Nectarine through this Website. Nectarine
              is not a client of any Independent Advisor listed on this website. The Referral Fee paid by an Independent Advisor to
              Nectarine creates a financial incentive for Nectarine to refer prospective clients to such Independent Advisor, which creates
              a material conflict of interest.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleDisclaimer}>
              OK
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </Col>
    </Row>
  );
};
